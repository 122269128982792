<template>
  <loading
    :active.sync="isLoading"
    :is-full-page="true"
    background-color="#000"
    color="#068bcd"
    :opacity="0.5"
    :width="70"
    :height="70">
    <div class="loader">
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
      </div>
    </div>
  </loading>
</template>

<script>
  export default {
    props : {
      isLoading : Boolean,
    },
    components : {
      'Loading' : () => import('vue-loading-overlay'),
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/partials/rainbow-loader';
</style>